var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"instructorForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meta_title"}},[_vm._v("Meta Title (SEO):")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.instructor.meta_title),expression:"instructor.meta_title"}],staticClass:"form-control",attrs:{"type":"text","name":"meta_title","id":"meta_title","placeholder":""},domProps:{"value":(_vm.instructor.meta_title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.instructor, "meta_title", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid meta title. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"meta_description"}},[_vm._v("Meta Description (SEO):")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.instructor.meta_description),expression:"instructor.meta_description"}],staticClass:"form-control",attrs:{"name":"meta_description","id":"meta_description","placeholder":""},domProps:{"value":(_vm.instructor.meta_description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.instructor, "meta_description", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid meta description. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"fullname"}},[_vm._v("Full Name:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.instructor.fullname),expression:"instructor.fullname"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('fullname')
        },attrs:{"type":"text","name":"fullname","id":"fullname","placeholder":""},domProps:{"value":(_vm.instructor.fullname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.instructor, "fullname", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid full name. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.instructor.email),expression:"instructor.email"},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],class:{ 'form-control': true, 'is-invalid': _vm.errors.has('email') },attrs:{"type":"email","name":"email","id":"email","placeholder":"Email"},domProps:{"value":(_vm.instructor.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.instructor, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid email. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"photo"}},[_vm._v("Photo:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.instructor.photo),expression:"instructor.photo"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{ 'form-control': true, 'is-invalid': _vm.errors.has('photo') },attrs:{"type":"text","name":"photo","id":"photo","placeholder":""},domProps:{"value":(_vm.instructor.photo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.instructor, "photo", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid photo link. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"body"}},[_vm._v("Profile Body:")]),_c('editor',{staticClass:"wysiwyg-editor",attrs:{"api-key":"h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86","name":"body","id":"body","init":{
          plugins:
            'wordcount link image fullscreen lists textcolor colorpicker'
        }},model:{value:(_vm.instructor.body),callback:function ($$v) {_vm.$set(_vm.instructor, "body", $$v)},expression:"instructor.body"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"release_date"}},[_vm._v("Sort Date:")]),_c('datepicker',{attrs:{"placeholder":"Select Date","name":"uniquename","input-class":"form-control"},model:{value:(_vm.instructor.sort_date),callback:function ($$v) {_vm.$set(_vm.instructor, "sort_date", $$v)},expression:"instructor.sort_date"}})],1),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group signup-carousel-buttons"},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")])])
}]

export { render, staticRenderFns }