<template>
  <div class="instructorForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="meta_title">Meta Title (SEO):</label>
        <input
          type="text"
          v-model="instructor.meta_title"
          class="form-control"
          name="meta_title"
          id="meta_title"
          placeholder=""
        />
        <div class="invalid-feedback">
          Please provide a valid meta title.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_description">Meta Description (SEO):</label>
        <textarea
          v-model="instructor.meta_description"
          class="form-control"
          name="meta_description"
          id="meta_description"
          placeholder=""
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>
      <div class="form-group">
        <label for="fullname">Full Name:</label>
        <input
          type="text"
          v-model="instructor.fullname"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('fullname')
          }"
          name="fullname"
          id="fullname"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid full name.
        </div>
      </div>

      <div class="form-group">
        <label for="email">Email:</label>
        <input
          type="email"
          v-model="instructor.email"
          :class="{ 'form-control': true, 'is-invalid': errors.has('email') }"
          name="email"
          id="email"
          placeholder="Email"
          v-validate="'required|email'"
        />
        <div class="invalid-feedback">
          Please provide a valid email.
        </div>
      </div>

      <div class="form-group">
        <label for="photo">Photo:</label>
        <input
          type="text"
          v-model="instructor.photo"
          :class="{ 'form-control': true, 'is-invalid': errors.has('photo') }"
          name="photo"
          id="photo"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid photo link.
        </div>
      </div>

      <div class="form-group">
        <label for="body">Profile Body:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="body"
          id="body"
          v-model="instructor.body"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker'
          }"
        ></editor>
      </div>

      <div class="form-group">
        <label for="release_date">Sort Date:</label>
        <datepicker
          placeholder="Select Date"
          v-model="instructor.sort_date"
          name="uniquename"
          input-class="form-control"
        ></datepicker>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Editor from "@tinymce/tinymce-vue";

export default {
  methods: {
    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API +
                "/instructors/" +
                this.$route.params.id,
              this.instructor,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        } else if (this.$route.params.id == "add") {
          this.$http
            .post(
              process.env.VUE_APP_ROOT_API + "/instructors",
              this.instructor,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record created successfully");
              }
            });
        }
      }
    }
  },

  data: function() {
    return {
      instructor: {}
    };
  },

  components: {
    datepicker: Datepicker,
    editor: Editor
  },

  created: function() {
    if (!isNaN(this.$route.params.id)) {
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API + "/instructors/" + this.$route.params.id
        )
        .then(response => {
          this.instructor = response.data;
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  }
};
</script>
<style></style>
